import React from 'react';
import * as styles from './index.module.scss';

const YoutubeVideo = ({ id }) => {
  return (
    <div className={styles.videoContainer}>
      <iframe
        title="Youtube Video"
        className={styles.video}
        src={`https://www.youtube.com/embed/${id}?rel=0&amp;controls=0&amp;showinfo=0;`}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YoutubeVideo;
