import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import content, { getStarted } from './content';
import GetStarted from '../Solutions/GetStarted';
import Section from '../Solutions/Section';

function ModelRegistryMain() {
  const images = useStaticQuery(query);

  return (
    <main>
      {content.map(({ title, text, image, imageText, imageAlt }, i) => (
        <Section
          title={title}
          text={text}
          image={images[image] || image}
          imageText={imageText}
          theme={i % 2 === 0 ? 'blue' : 'orange'}
          imageAlt={imageAlt}
        />
      ))}
      <GetStarted {...getStarted} />
    </main>
  );
}

export default ModelRegistryMain;

const query = graphql`
  query getExampleImages {
    gitTagsAndStudio: file(
      relativePath: { regex: "/model-registry/git-tags-and-studio.png$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 750
          placeholder: BLURRED
          quality: 90
        )
      }
    }
    modelsDashboard: file(
      relativePath: { regex: "/model-registry/models-dashboard.png$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 750
          placeholder: BLURRED
          quality: 90
        )
      }
    }
    cliAndGui: file(
      relativePath: { regex: "/model-registry/cli-and-gui.png$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 750
          placeholder: BLURRED
          quality: 90
        )
      }
    }
  }
`;
