import React from 'react';
import Layout from '../components/Layout/Layout';
import ModelRegistryMain from '../components/ModelRegistryMain';
import Header from '../components/Header/Header';
import PageTitle from '../components/PageTitle/PageTitle';
import Points from '../components/Solutions/Points';
import Footer from '../components/Footer/Footer';
import { points } from '../components/ModelRegistryMain/content';
import PageDescription from '../components/ModelRegistryMain/PageDescription';

function ModelRegistry() {
  return (
    <Layout title="Git-backed Model Registry for Machine Learning">
      <Header>
        <PageTitle title="Git-backed Model Registry for Machine Learning" />
        <PageDescription text="Use your Git repositories to build a model registry with model versioning, lineage, and lifecycle management." />
        <Points points={points} />
      </Header>
      <ModelRegistryMain />
      <Footer />
    </Layout>
  );
}

export default ModelRegistry;
