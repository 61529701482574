import React from 'react';
import RequestDemo from '../../RequestDemo';
import YoutubeVideo from '../../YoutubeVideo';
import * as styles from './index.module.scss';

const PageDescription = ({ text }) => {
  return (
    <div className={styles.pageDescription__main}>
      <div>
        <p className={styles.pageDescription__text}>{text}</p>
        <RequestDemo />
      </div>
      <div className={styles.pageDescription__video}>
        <YoutubeVideo id="DYeVI-QrHGI" />
      </div>
    </div>
  );
};

export default PageDescription;
