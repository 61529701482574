import React from 'react';
import BaseLink from '../BaseLink/BaseLink';
import iterativeDiagram from '../../images/iterative-diagram.svg';

export const points = [
  {
    title: 'Organization, Discovery and Collaboration',
    text: 'Curate your models in a central dashboard that facilitates model discovery across all your ML projects',
  },
  {
    title: 'Deployment automation',
    text: 'Track, deploy, and manage models through the development lifecycle and integrate with CI/CD solutions like Heroku',
  },
  {
    title: 'Unifying ML projects with DevOps',
    text: 'Manage model lifecycle through Git commits and tags, unifying ML projects with the best DevOps practices every step of the way',
  },
];

export const getStarted = {
  title: 'Ready to build your model registry?',
  text: 'Reach out to one of our MLOps experts!',
  buttonText: 'Contact us!',
};

const content = [
  {
    title: 'Build your model registry on top of Git',
    text: 'Reuse existing Git infrastructure for managing ML models together with code, data and metrics. With git as your single source of truth, use GitOps for model deployment.',
    image: 'gitTagsAndStudio',
    imageAlt:
      "An example showing git tags and it's respective stage in Iterative Studio dashboard.",
    imageText: (
      <>
        Answer questions like,
        <ul>
          <li>Which model version is currently in production?</li>
          <li>Who created this model version and when?</li>
          <li>Which code and dataset was used to train it?</li>
          <li>Who put this model into production?</li>
          <li>and more.</li>
        </ul>
      </>
    ),
  },
  {
    title:
      'Manage your ML models through the entire lifecycle, from development to production.',
    text: 'Create versions and assign stages to models to build a timeline of model actions from experimentation to production.',
    image: 'modelsDashboard',
    imageAlt:
      "An example in Iterative's Studio product. Shows a page where you can view list of all your model.",
    imageText:
      'Manage the lifecycle of each model as it moves through staging, production and other stages. See at a glance which model versions are in which stage.',
  },
  {
    title: 'Use the interface of your choice',
    text: (
      <>
        Register, track, and version models with command line or from{' '}
        <BaseLink href="https://studio.iterative.ai/">
          Iterative Studio
        </BaseLink>
        .
      </>
    ),
    image: 'cliAndGui',
    imageAlt:
      'An example using both cli terminal and Iterative Studio dashboard to get info on project.',
    imageText:
      'Use the interface that you’re most comfortable with, whether you’re a data scientist who likes APIs, or a manager who prefers a web UI, or a DevOps engineer who works best with the command line interface (CLI) and uses GitOps principles for model deployment.',
  },
  {
    title: "Build your model registry with Iterative's ecosystem of tools",
    text: 'Use Iterative tools for even more benefits, like linking versioned data with your code and models.',
    image: iterativeDiagram,
    imageAlt:
      'An example of the iterative.ai ecosystem. The example shows two layers, the bottom layer representing third part tools and the top layer represents iterative.ai products.',
    imageText: (
      <>
        Gain additional capabilities around data versioning, pipeline
        management, experiment tracking, training automation, and more with
        Iterative tools including{' '}
        <BaseLink href="https://dvc.org">DVC</BaseLink> and{' '}
        <BaseLink href="https://cml.dev">CML</BaseLink>. Iterative's products
        are modular, so you can pick those components that extend what you
        already have. Because you work with your existing cloud and DevOps
        infrastructure, you can avoid vendor lock-in.
      </>
    ),
  },
];

export default content;
